import React, { useState, useEffect } from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  VStack,
  Code,
  Grid,
  Flex,
  Spacer,
  Image,
  HStack,
} from '@chakra-ui/react';
import theme from './theme.js';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import { TokenPage } from './components/TokenList';
import { TokenInfo } from './components/TokenInfo';
import { useInterval } from 'react-use';
import { Routes, Route, Outlet, Link } from 'react-router-dom';
import logo from './assets/icpcoinss.png';
import { CryptoCurrencyMarket } from "react-ts-tradingview-widgets";

function App() {
  let [tokens, setTokens] = useState([]);

  const refresh = async () => {
    // let data = await fetch('/list.json').then(x =>
    let data = await fetch('https://nftpkg.com/api/icpcoins/list').then(x =>
      x.json()
    );
    data = data.sort((a, b) => b.marketcap - a.marketcap);
    data = data.filter(x => !x.unreleased);
    setTokens(data);
  };

  useInterval(() => {
    refresh();
  }, 10000);

  useEffect(() => {
    refresh();
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <Box pl="15px" pr="15px" pb="50vh">
        <Box color="gray.500" mt="6" fontSize="sm" textAlign={'center'}>
        </Box>
      </Box>
    </ChakraProvider>
  );
}

export default App;
